import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import FacilitySlide from './FacilitySlide/FacilitySlide';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "facilities"}, page: {eq: "main"}) {
      title
      header
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
        }
      }
    }
  }
`;

interface IFacilities {
  strapiWidgets: IWidget<null, IMediaImage>;
}

const Facilities: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IFacilities) => {
      if (!data?.strapiWidgets) return null;
      const { slide = [] } = data.strapiWidgets;

      return (
        <>
          {slide.map((item) => (
            <FacilitySlide
              key={`facilitySlide-${item.id}`}
              {...item}
            />
          ))}
        </>
      );
    }}
  />
);

export default Facilities;
