import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';
import { GatsbySvg, Layout, MiddleText } from 'UI';

export const Wrapper = styled(Layout)`
  background: var(--black);
  color: var(--white);
  padding-top: 80px;
  padding-bottom: 142px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 102px;
    padding-bottom: 50px;
    row-gap: 6em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 120px;
    padding-bottom: 70px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 240px;
    padding-bottom: 110px;
  }

  .parallax-outer {
    grid-column: 4/-1;
    grid-row: 2;
    align-self: end;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      grid-column: 1/-1;
    }
  }
`;

export const NewsWrapper = styled.div`
  display: grid;
  align-self: flex-end;
  justify-self: center;
  min-height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 20px 80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    width: 100%;
    gap: 80px 40px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding: 0;
    flex-direction: column;
    gap: 40px;
    grid-template-columns: 1fr;
  }

  @media (min-width: ${MIN_2K}px) {
    gap: 25px 180px;
  }

  @media (min-width: ${MIN_4K}px) {
    gap: 110px 350px;
  }
`;

export const TitleWrapper = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
  white-space: pre-line;
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
`;

export const Title = styled.h2``;

export const NewsTitle = styled(MiddleText)`
  white-space: pre-line;
  letter-spacing: normal;
  position: relative;
  color: var(--white-opacity-1);
  margin-bottom: 20px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    white-space: normal;
    color: var(--white);
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: 10px;
  }

  @media (min-width: ${MIN_2K}px) {
    margin-bottom: 24px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-bottom: 48px;
  }
`;

export const Icon = styled(GatsbySvg)`
  transform-origin: top left;

  path {
    fill: var(--white);

    @media (max-width: 1279px) {
      fill: var(--red);
    }
  }

  @media (min-width: ${MIN_2K}px) {
    transform: scale(1.25);
  }

  @media (min-width: ${MIN_4K}px) {
    transform: scale(2.8);
  }
`;

export const HoverSpan = styled.span`
  color: var(--red);
  position: absolute;
  font-size: 0.6em;
  line-height: 2.2;
  margin-left: 0.4em;
  display: none;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: inline-block;
  }
`;

export const NewsWrap = styled.a`
  width: 320px;
  cursor: pointer;
  display: block;

  :last-of-type {
    align-self: end;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    width: 100%;
  }

  @media (min-width: ${MIN_2K}px) {
    width: 510px;
  }

  @media (min-width: ${MIN_4K}px) {
    width: 100%;
    max-width: 1050px;
  }

  :hover {
    ${NewsTitle} {
      color: var(--white);
    }

    ${Icon} {
      path {
        fill: var(--red) !important;
      }
    }

    ${HoverSpan} {
      display: inline-block;
    }
  }
`;
