import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GatsbySvg, Layout } from 'UI';

import {
  MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

export const Container = styled(Layout)`
  min-height: 100vh;
  gap: 40px;

  align-content: center;

  @media (min-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 6em;
    padding-bottom: 6em;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export const Span = styled.span`
  ${({ color }) => (color
    ? css`
          color: ${color};
        `
    : '')}
`;

export const Title = styled.h2`
  grid-column: 1/5;
  grid-row: 1;

  align-self: end;

  @media (min-width: ${MIN_2K}px) {
    grid-row: 2;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 1;
    grid-column: 1/-1;
  }
`;

export const TextOne = styled.p`
  grid-column: 1/7;
  grid-row: 2;

  @media (min-width: ${MIN_2K}px) {
    grid-row: 3;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 2;
    grid-column: 1/-1;
  }
`;

export const TextTwo = styled.p`
  grid-column: 8/11;
  grid-row: 1;
  margin-bottom: 60px;

  align-self: end;

  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0em;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 60px;
    margin-bottom: 20px;
    grid-row: 4;
    grid-column: 3/-1;
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 40px;
    line-height: 44px;
  }

  @media (min-width: ${MIN_4K}px) {
    font-size: 80px;
    line-height: 84px;
    letter-spacing: -0.01em;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/4;

    font-size: 18px;
    line-height: 22px;
  }
`;

export const TextThree = styled.p`
  grid-column: 9/11;
  grid-row: 2/4;

  @media (min-width: ${MIN_2K}px) {
    grid-row: 3/5;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 5;
    grid-column: 3/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const Links = styled.div`
  grid-row: 4;
  grid-column: 9/11;

  @media (min-width: ${MIN_2K}px) {
    grid-row: 5;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 6;
    grid-column: 3/-1;

    margin-top: 20px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 2/-1;
  }
`;

export const Funders = styled.div`
  grid-row: 3/5;
  grid-column: 1/8;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 18%));
  gap: 20px;
  align-items: center;
  justify-content: space-around;

  margin-top: 20px;
  filter: grayscale(1);

  @media (min-width: ${MIN_2K}px) {
    grid-row: 4;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 3;
    grid-column: 1/-1;

    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const Logo = styled(GatsbySvg)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 6em;

  svg {
    width: 85%;
    max-height: 5em;
    height: auto;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      max-height: 64px;
      max-width: 122px;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 6em;
  align-items: center;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: 80px;
  }

  img {
    width: 80%;
    max-height: 4em;
    height: auto;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      max-width: 122px;
    }
  }

  svg {
    width: 80%;
    max-height: 4em;
    height: auto;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      max-height: 64px;
      max-width: 122px;
    }
  }
`;
