import styled from '@emotion/styled';

import {
  MAX_MOB_WIDTH,
  MAX_TABLET_WIDTH,
  MIN_2K,
  MIN_4K,
  MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import {
  Wrapper as MenuWrapper,
  SliderMenuLink,
} from 'UI/ScrollableSlider/SliderMenu/styled';

export const Container = styled.div`
  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: ${MOBILE_TOP_MENU_HEIGHT}px;
  }

  ${MenuWrapper} {
    > ${SliderMenuLink}:nth-of-type(1) {
      display: none;
    }
  }
`;

export const SlidesContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    max-height: 100vh;
  }
`;

export const LinkWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 80px;
  color: white;
  z-index: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    bottom: auto;
    top: calc(100% - 100px);
    left: 48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    left: 20px;
    top: calc(100% - 60px);
  }

  @media (min-width: ${MIN_2K}px) {
    bottom: 120px;
    left: 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    bottom: 160px;
    left: 240px;
  }
`;
