import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MIN_4K,
  MIN_2K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
  MOBILE_TOP_MENU_HEIGHT,
} from 'constants/sizes';
import { GatsbyPicture, Layout, MiddleText } from 'UI';

const textStyle = css`
  opacity: 0;
  transform: translateY(-50px);
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  will-change: opacity, transform;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  padding: 40px 32px 0;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding: 40px 16px 0;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding: 20px 8px 0;
  }

  @media (min-width: ${MIN_2K}px) {
    padding: 48px 48px 0;
  }

  @media (min-width: ${MIN_4K}px) {
    padding: 80px 80px 0;
  }
`;

export const Image = styled(GatsbyPicture)`
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transform: scale(1.2);
  clip-path: inset(0 0 100% 0);
  transform-origin: top center;
  will-change: clip-path, transform;
  transition: clip-path 0.8s ease-in-out, transform 0.8s ease-in-out;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const TextLayout = styled(Layout)`
  min-height: 280px;
  align-items: center;
  justify-content: start;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    min-height: 260px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    min-height: 195px;
  }

  @media (min-width: ${MIN_2K}px) {
    min-height: 470px;
  }

  @media (min-width: ${MIN_4K}px) {
    min-height: 860px;
  }
`;

export const Title = styled.h1`
  ${textStyle}

  grid-column: 1/5;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
    align-self: end;
  }
`;

export const Description = styled(MiddleText)`
  ${textStyle}

  grid-column: 5/11;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    width: 100%;
    grid-row: 2;
    grid-column: 1/-1;
    align-self: start;
  }
`;

export const Section = styled.section`
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  &.active {
    ${Image} {
      transform: scale(1);
      clip-path: inset(0 0 0% 0);
    }

    ${Title},
    ${Description} {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: ${MOBILE_TOP_MENU_HEIGHT}px;
    padding-bottom: 40px;
  }

  @media (min-width: ${MAX_MOB_WIDTH}px) and (max-height: 620px) {
    ${Image} {
      transform: scale(1);
      clip-path: inset(0 0 0% 0);
    }

    ${Title},
    ${Description} {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;
