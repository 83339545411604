import React, {
  FC, useEffect, useLayoutEffect, useState,
} from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { ParallaxProvider, Parallax, useController } from 'react-scroll-parallax';

import { IWidget } from 'interfaces/widgets';
import { IMediaSvg } from 'interfaces/media';

import { NextText } from 'UI';

import {
  Wrapper, Title, NewsWrapper, NewsTitle, NewsWrap, Icon, HoverSpan, TitleWrapper,
} from './PressAboutUsBlock.styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "press"}, page: {eq: "main"}) {
      title
      header
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaSVGFragment
        }
      }
    }
  }
`;

interface INewsItem {
  title: string;
  media: IMediaSvg;
  link: string;
}

const News: FC<INewsItem> = ({ title, media, link }) => (
  <NewsWrap href={link} rel="noopener noreferrer" target="_blank">
    <NewsTitle>
      {title}
      <HoverSpan>↗</HoverSpan>
    </NewsTitle>
    <Icon media={media} />
  </NewsWrap>
);

interface IPressAboutUs {
  strapiWidgets: IWidget<null, IMediaSvg>;
}

const PressAboutUsBlock: FC = () => {
  const [isItMobile, setIsItMobile] = useState<boolean>(false);
  const [isItTablet, setIsItTablet] = useState<boolean>(false);

  const ParallaxCache = () => {
    const isServer = typeof window === 'undefined';

    if (!isServer) {
      // eslint-disable-next-line
      const { parallaxController } = useController();

      useLayoutEffect(() => {
        // eslint-disable-next-line
        const handler = () => parallaxController.update();
        window.addEventListener('load', handler);
        return () => window.removeEventListener('load', handler);
      }, [parallaxController]);
    }

    return null;
  };

  const setWidth = () => {
    if (window.matchMedia(`(max-width: ${767}px)`).matches) {
      setIsItMobile(true);
    } else if (window.matchMedia(`(max-width: ${1279}px)`).matches) {
      setIsItTablet(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setWidth, true);

    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  return (
    <StaticQuery
      query={query}
      render={(data: IPressAboutUs) => {
        if (!data?.strapiWidgets) return null;
        const { header, slide = [] } = data.strapiWidgets;

        return (
          <Wrapper>
            <ParallaxProvider>
              <TitleWrapper>
                <Title>{`${header}`}</Title>
                <NextText>
                  <Link to="/press-area/">
                    WayRay Press Area
                  </Link>
                </NextText>
              </TitleWrapper>
              <ParallaxCache />
              <Parallax
                y={isItTablet || isItMobile ? [0, 0] : [-30, 20]}
                styleOuter={{ maxWidth: isItTablet || isItMobile ? '100%' : '69.4%' }}
              >
                <NewsWrapper>
                  {slide.map((item) => (
                    <News
                      key={`slideAboutUs_${item.title}`}
                      title={item.title}
                      media={item.media}
                      link={item.header}
                    />
                  ))}
                </NewsWrapper>
              </Parallax>
            </ParallaxProvider>
          </Wrapper>
        );
      }}
    />
  );
};

export default PressAboutUsBlock;
