import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget, IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaVideo } from 'interfaces/media';

import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo';
import SlideThree from './SlideThree/SlideThree';

import { Container } from './UI';
import { Section, Title } from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "WhatWeDo"}, page: {eq: "main"}) {
      title
      header
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaVideoFragment
        }
        text {
          title
          text
        }
      }
    }
  }
`;

interface IWhatWeDo {
  strapiWidgets: IWidget<null, IMediaVideo>;
}

const WhatWeDo: FC = () => {
  const getVideoSrc = (media: IMediaVideo) => {
    const {
      localFile: { FHD, qHD },
    } = media;

    return {
      1080: FHD.path,
      540: qHD.path,
    };
  };

  const getSlideProps = ({ text, title, media }: IComponentWidgetSlide<IMediaVideo>) => ({
    title,
    footnote: text.find((item) => item.title === 'footnote')?.text || '',
    description: text.find((item) => item.title === 'description')?.text || '',
    videoSrc: getVideoSrc(media),
    preview: media.localFile.videoScreenshots?.[0].publicURL,
  });

  return (
    <StaticQuery
      query={query}
      render={(data: IWhatWeDo) => {
        if (!data?.strapiWidgets) return null;
        const { slide, header } = data.strapiWidgets;

        return (
          <Section>
            <Container>
              <Title>{header}</Title>
            </Container>
            {slide?.[0] && <SlideOne {...getSlideProps(slide[0])} />}
            {slide?.[1] && <SlideTwo {...getSlideProps(slide[1])} />}
            {slide?.[2] && <SlideThree {...getSlideProps(slide[2])} />}
          </Section>
        );
      }}
    />
  );
};

export default WhatWeDo;
