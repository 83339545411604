import styled from '@emotion/styled';

export const SlidesList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

export const Container = styled.div``;
