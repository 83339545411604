import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

export const ContentPadding = css`
  margin: 0 80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin: 0 48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin: 0 20px;
  }

  @media (min-width: ${MIN_2K}px) {
    margin: 0 120px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin: 0 240px;
  }
`;

export const Container = styled.div`
  padding: 70px 0 0;
  position: relative;
  display: grid;
  grid-template-columns: 420px auto 420px;
  width: 100%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-template-columns: 228px auto 228px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-template-columns: 155px auto 155px;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-template-columns: 600px auto 600px;
  }

  @media (min-width: ${MIN_4K}px) {
    grid-template-columns: 1200px auto 1200px;
  }
`;
