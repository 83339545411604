import styled from '@emotion/styled';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH, MIN_4K } from 'constants/sizes';
import { Layout as UILayout } from 'UI';

export const Title = styled.h1`
  grid-column: 1/-1;
  grid-row: 1;

  @media (min-width: ${MIN_4K}px) {
    grid-column: 1/9;
  }
`;

export const TextOne = styled.p`
  grid-column: 7/9;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 3/-1;
  }
`;

export const TextTwo = styled.p`
  grid-column: 9/11;
  grid-row: 2;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 3;
    grid-column: 4/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 3/-1;
  }
`;

export const Layout = styled(UILayout)`
  row-gap: 140px;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 0;
  }
`;
