import styled from '@emotion/styled';

import { MiddleText } from 'UI';

import { ContentPadding } from '../Container/Container';

const Header = styled(MiddleText)`
  ${ContentPadding}

  color: var(--red);
`;

export default Header;
