import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

import { ContentPadding } from '../Container/Container';

const Footnote = styled.p`
  ${ContentPadding}

  color: #000;

  margin-top: 34px;
  padding: 5px 0;
  width: 208px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    width: 255px;
    margin-top: 80px;
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    width: 158px;
    margin-top: 40px;
  }

  @media (min-width: ${MIN_2K}px) {
    width: 300px;
    margin-top: 72px;
  }

  @media (min-width: ${MIN_4K}px) {
    width: 575px;
    margin-top: 520px;
  }
`;

export default Footnote;
