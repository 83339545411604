import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MIN_2K, MIN_4K } from 'constants/sizes';

import { MiddleText } from 'UI';
import { ContentPadding } from '../Container/Container';

const Description = styled(MiddleText)`
  ${ContentPadding}

  color: #000;

  padding: 5px 0;
  max-width: 780px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 16px;
    max-width: 100%;
    grid-column: 1/4;
  }

  @media (min-width: ${MIN_2K}px) {
    max-width: 1100px;
  }

  @media (min-width: ${MIN_4K}px) {
    max-width: 2000px;
  }
`;

export default Description;
