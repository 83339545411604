import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import {
  MIN_4K, MIN_2K, MAX_TABLET_WIDTH, MAX_MOB_WIDTH,
} from 'constants/sizes';
import { GatsbyPicture, Layout } from 'UI';

export const Container = styled(Layout)`
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 0;
  align-items: end;
  align-content: end;
  padding-bottom: 40px;
  background: #000;

  color: #fff;

  @media (min-width: ${MIN_2K}px) {
    padding-bottom: 80px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-bottom: 200px;
  }
`;

export const Title = styled.h2`
  grid-column: 1/4;
  grid-row: 1/3;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 1;
  }
`;

export const Text = styled(Markdown)`
  align-self: start;
  grid-row: 1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 2;
  }
`;

export const TextLeft = styled(Text)`
  grid-column: 7/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/3;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 5/8;
  }

  @media (min-width: ${MIN_4K}px) {
  }
`;

export const TextRight = styled(Text)`
  grid-column: 9/11;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/7;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 3/5;
  }

  @media (min-width: ${MIN_2K}px) {
    grid-column: 8/11;
  }
`;

export const Picture = styled(GatsbyPicture)`
  position: absolute;
  z-index: -1;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  > img,
  > source {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 41%, #000000 80%);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    height: 70.42%;

    ::before {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #000000);
    }
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 20px;
  grid-row: 2;
  grid-column: 9/11;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 4/7;
    grid-row: 3;
    margin-top: 10px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 3/5;
    margin-top: 20px;
  }

  @media (min-width: ${MIN_2K}px) {
    justify-self: end;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-top: 100px;
  }
`;
