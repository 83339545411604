import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';
import { Video as UIVideo } from 'UI';

export const Img = styled.img`
  width: 100%;
`;

export const Video = styled(UIVideo)`
  width: 100%;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-top: 80px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-top: 60px;
    box-shadow: 10px 0 0 #000, -10px 0 0 #000;
  }

  @media (min-width: ${MIN_2K}px) {
    margin-top: 96px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-top: 280px;
  }
`;
