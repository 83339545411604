import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { NextLink, ScaleVisible, NextText } from 'UI';

import {
  Container, Picture, Title, TextLeft, TextRight, LinkWrapper,
} from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "our team"}, page: {eq: "main"}) {
      title
      header
      strapiId
      text {
        text
      }
      media {
        ...MediaImageFragment
      }
    }
  }
`;

interface IOurTeam {
  strapiWidgets: IWidget<IMediaImage, null>;
}

const OurTeam: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IOurTeam) => {
      if (!data?.strapiWidgets) return null;
      const { header, text = [], media } = data.strapiWidgets || {};

      return (
        <ScaleVisible>
          <Container>
            <Title>{header}</Title>

            <TextLeft>{text[0]?.text}</TextLeft>
            <TextRight>{text[1]?.text}</TextRight>

            <LinkWrapper>
              <NextText>
                <NextLink path="/culture/" title="Culture & Jobs">
                  Learn more
                  <br />
                  about our culture
                </NextLink>
              </NextText>
            </LinkWrapper>

            <Picture
              {...media?.[0]}
              title={header}
            />
          </Container>
        </ScaleVisible>
      );
    }}
  />
);

export default OurTeam;
