import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { Layout, GatsbyPicture } from 'UI';

const slideOut = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
`;

const textIn = keyframes`
  0%    { opacity: 0; }
  10%   { opacity: 0; }
  100%  { opacity: 1; }
`;

const contentBack = keyframes`
  0%    { opacity: 1; }
  100%  { opacity: 0; }
`;

const contentAppear = keyframes`
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`;

export const Content = styled(Layout)`
  position: relative;
  margin-top: auto;
  width: 100%;
  min-width: 100%;
  bottom: 5em;
  z-index: 0;

  opacity: 0;
  animation: ${slideOut} 0.3s ease-in-out;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
`;

export const Title = styled.h3`
  grid-row: 1;
  grid-column: 1/4;
`;

export const Paragraph = styled.p`
  grid-row: 2;
  grid-column: 1/4;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #000;

  animation: ${contentBack} 0.8s ease-out;
  animation-fill-mode: forwards;

  will-change: opacity;

  ::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const Picture = styled(GatsbyPicture)`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.3s ease-in-out;

  source,
  img {
    object-fit: cover;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  color: white;

  visibility: hidden;
  transition: visibility 2s linear;

  &.active {
    animation: ${contentAppear} 0s;
    animation-fill-mode: forwards;
    z-index: -1;

    ${Background} {
      animation: ${slideIn} 0s;
      animation-fill-mode: forwards;
      z-index: -2;
    }

    ${Content} {
      opacity: 0;
      animation: ${textIn} 1s ease-in-out;
      animation-fill-mode: forwards;
      opacity: 1;
      z-index: 1;
    }
  }
`;
