import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IComponentWidgetText } from 'interfaces/widgets';
import { IMediaSvg } from 'interfaces/media';

import { NextText } from 'UI';

import {
  Container,
  Title,
  TextOne,
  TextTwo,
  TextThree,
  Funders,
  Links,
  Span,
  Logo,
} from './Achievements.styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "TotalFunding"}, page: {eq: "main"}) {
      title
      strapiId
      text {
        title
        text
      }
      media {
        ...MediaSVGFragment
      }
    }
  }
`;

interface IAchievements {
  strapiWidgets: {
    id: number;
    title: string;
    header: string;
    page: string;
    media: IMediaSvg[];
    text: IComponentWidgetText[];
  };
}

// const { title, text, funders } = blockData;
const Achievements: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IAchievements) => {
      if (!data?.strapiWidgets) return null;
      const { text = [], media = [] } = data.strapiWidgets;

      return (
        <Container withPaddings>
          <Title>
            <Span style={{ color: 'var(--red)' }}>{text[0]?.title}</Span>
            <br />
            {text[0]?.text}
          </Title>
          <TextOne>{text[1]?.text}</TextOne>
          <TextTwo>{text[2]?.text}</TextTwo>
          <TextThree>{text[3]?.text}</TextThree>

          <Funders>
            {media.map((item) => (
              <Logo
                wrapper="div"
                key={`funder-${item.id}`}
                title={item.alternativeText}
                media={item}
              />
            ))}
          </Funders>

          <Links>
            <NextText>
              <a title="Mail to us" href="mailto:info@wayray.com">
                Let’s talk
              </a>
            </NextText>
          </Links>
        </Container>
      );
    }}
  />
);

export default Achievements;
