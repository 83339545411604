import React, { FC, useRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { Intersection } from 'UI';

import {
  Section, Title, TextLayout, Description, ImageContainer, Image,
} from './styled';

const FacilitySlide: FC<IComponentWidgetSlide<IMediaImage>> = ({
  header, title, media,
}) => {
  const containerRef = useRef<HTMLDivElement>();

  const handleAppear = ({ visibility }) => {
    if (visibility) {
      containerRef?.current?.classList?.add('active');
    }
  };

  return (
    <Intersection id={`FacilitySlide${title}`} options={{ threshold: 0.35 }} way={handleAppear}>
      <Section ref={containerRef}>
        <ImageContainer>
          <Image
            {...media}
            title={title}
          />
        </ImageContainer>
        <TextLayout>
          <Title>{title}</Title>
          <Description>{header}</Description>
        </TextLayout>
      </Section>
    </Intersection>
  );
};

export default FacilitySlide;
