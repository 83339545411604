import React, { FC } from 'react';

import {
  Container, Header, Description, Footnote, Media,
} from './styled';

const Slide: FC<ISlide> = ({
  title,
  footnote,
  description,
  videoSrc,
  preview,
}) => (
  <Container>
    <Header>{title}</Header>
    <Description>{description}</Description>
    <Footnote>{footnote}</Footnote>
    <Media
      src={videoSrc?.[1080]}
      srcSet={videoSrc}
      previewSrc={preview}
    />
  </Container>
);

export default Slide;
