import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  Container as UIContaienr,
  Header as UIHeader,
  Description as UIDescription,
  Footnote as UIFootnote,
  Media as UIMedia,
} from '../UI';

export const Container = styled(UIContaienr)``;

export const Header = styled(UIHeader)`
  grid-row: 1;
  grid-column: 1/4;
`;

export const Description = styled(UIDescription)`
  grid-row: 2;
  grid-column: 1/4;
`;

export const Footnote = styled(UIFootnote)`
  grid-row: 3;
  grid-column: 3;
  align-self: end;
  justify-self: end;
  margin-left: 0;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-left: 0;
  }
`;

export const Media = styled(UIMedia)`
  grid-row: 4;
  grid-column: 1/4;
  margin-top: 70px;
  width: 100%;
`;
