import styled from '@emotion/styled';

import { MAX_MOB_WIDTH } from 'constants/sizes';
import { SectionTitle } from 'UI';

import { ContentPadding } from './UI';

export const Section = styled.section`
  min-height: 100vh;
  position: relative;
  margin-bottom: 126px;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-bottom: -60px;
  }
`;

export const Title = styled(SectionTitle)`
  ${ContentPadding}
`;
