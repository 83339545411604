import React, { FC } from 'react';

import { Video } from './styled';

interface IMedia {
  src: string;
  srcSet: {
    [resolution: number]: string;
  };
  previewSrc?: string;
  className?: string;
}

const Media: FC<IMedia> = ({
  src, srcSet, previewSrc = '', className = '', ...props
}) => (
  <Video
    loop
    preload="metadata"
    src={src}
    poster={previewSrc}
    srcSet={srcSet}
    type="video/mp4"
    className={className}
    {...props}
  />
);

export default Media;
