import styled from '@emotion/styled';

import {
  MAX_TABLET_WIDTH, MAX_MOB_WIDTH, MIN_2K, MIN_4K,
} from 'constants/sizes';

import {
  Container as UIContaienr,
  Header as UIHeader,
  Description as UIDescription,
  Footnote as UIFootnote,
  Media as UIMedia,
} from '../UI';

export const Container = styled(UIContaienr)`
  padding-top: 240px;

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    padding-top: 40px;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-top: 100px;
  }

  @media (min-width: ${MIN_2K}px) {
    padding-top: 340px;
  }

  @media (min-width: ${MIN_4K}px) {
    padding-top: 800px;
  }
`;

export const Header = styled(UIHeader)`
  grid-column: 1/4;
  grid-row: 1;
`;

export const Description = styled(UIDescription)`
  grid-column: 1/4;
  grid-row: 2;
  margin-bottom: 70px;
`;

export const Footnote = styled(UIFootnote)`
  grid-row: 3;
  grid-column: 1;
  align-self: end;
  justify-self: start;
  margin-right: 135px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-row: 4;
    grid-column: 1/4;
  }
`;

export const Media = styled(UIMedia)`
  grid-row: 3;
  grid-column: 2/4;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/4;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/4;
  }
`;
