import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import Slides from './Slides';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "CoreProcesses"}, page: {eq: "main"}) {
      title
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
        }
        text {
          title
          text
        }
      }
    }
  }
`;

interface ISliderWrapper {
  strapiWidgets: IWidget<IMediaImage, IMediaImage>;
}

const CoreProcesses: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ISliderWrapper) => {
      if (!data?.strapiWidgets) return null;
      const { slide = [] } = data.strapiWidgets;

      return (
        <Slides slides={slide} />
      );
    }}
  />
);

export default CoreProcesses;
