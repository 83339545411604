import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Title, Content, Background, Picture, Paragraph,
} from './styled';

interface ISlideItem extends IComponentWidgetSlide<IMediaImage> {
  index: number;
}

const Slide = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, media, index,
  }, ref) => (
    <Container ref={ref} data-index={index} className={index === 0 ? 'active' : ''}>
      <Content withPaddings>
        <Title>{title}</Title>
        <Paragraph className="slideText">{text?.[0].text || ''}</Paragraph>
      </Content>
      <Background>
        {media && (<Picture {...media} />)}
      </Background>
    </Container>
  ),
);

export default Slide;
