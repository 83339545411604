import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { IQueryPage } from 'interfaces/page';

import { setActiveBlock, setPreviousBlock } from 'store/actions';

import Head from 'components/Layout/Head/Head';

import OurTeam from 'components/blocks/MainPage/OurTeam/OurTeam';
import PressAboutUsBlock from 'components/blocks/MainPage/PressAboutUsBlock/PressAboutUsBlock';
import Achievements from 'components/blocks/MainPage/Achievements/Achievements';
import WhatWeDo from 'components/blocks/MainPage/WhatWeDo/WhatWeDo';
import WelcomeBlock from 'components/blocks/MainPage/WelcomeBlock/WelcomeBlock';
import TechSlider from 'components/blocks/MainPage/TechSlider/TechSlider';
import CoreProcesses from 'components/blocks/MainPage/CoreProcesses/CoreProcesses';
import Facilities from 'components/blocks/MainPage/Facilities/Facilities';
import { Intersection } from 'UI';
// import ModalBlock from 'components/blocks/ModalBlock/ModalBlock';

const IndexPage: FC = () => {
  const dispatch = useDispatch();

  const setBlock = ({ title, visibility }: ISetBlock) => {
    if (visibility) {
      dispatch(setActiveBlock(`#${title}`));
    } else {
      dispatch(setPreviousBlock(`#${title}`));
    }
  };

  return (
    <>
      <Intersection id="who-we-are" way={setBlock}>
        <WelcomeBlock />

        <TechSlider />
      </Intersection>

      <Intersection id="what-we-do" way={setBlock}>
        <WhatWeDo />
      </Intersection>

      <Intersection id="how-we-work" way={setBlock}>
        <CoreProcesses />
      </Intersection>

      <Intersection id="achievements" way={setBlock}>
        <Achievements />

        <PressAboutUsBlock />
      </Intersection>

      <Intersection id="facilities" way={setBlock}>
        <Facilities />

        <OurTeam />
      </Intersection>
    </>
  );
};

const query = graphql`
  query {
    page: strapiHomePage {
      meta {
        ...StrapiMetaFragment
      }
    }
  }
`;

const HomePageQuery: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
          />
          <IndexPage />
        </>
      );
    }}
  />
);

export default HomePageQuery;
