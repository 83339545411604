import React, {
  FC, useRef, createRef, useEffect,
} from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { NextLink, ScrollableSlider, NextText } from 'UI';

import Slide from './Slide/Slide';
import { Container, LinkWrapper, SlidesContainer } from './styled';

interface ICoreProcessesSlides {
  slides: IComponentWidgetSlide<IMediaImage>[];
}

const CoreProcesses: FC<ICoreProcessesSlides> = ({ slides }) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef(slides.map(() => createRef<HTMLDivElement>()));

  let activeSlide = 0;

  const setActiveClass = (slide = 0) => {
    const nodes = slideRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => (index === slide ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const handleChangeSlide = (slide: number = activeSlide) => {
    activeSlide = slide;

    setActiveClass(slide);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility) handleChangeSlide(activeSlide);
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  return (
    <Container ref={containerRef}>
      <ScrollableSlider
        ref={sliderRef}
        items={slides.map(({ title }) => title)}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        appear="scaleVisible"
      >
        <SlidesContainer>
          {slides.map((item, index) => (
            <Slide
              {...item}
              key={`slider-${item.title}`}
              index={index}
              ref={slideRefs.current[index]}
            />
          ))}

          <LinkWrapper>
            <NextText>
              <NextLink path="/technology/" title="Technology">
                More about
                <br />
                {' '}
                our technology
              </NextLink>
            </NextText>
          </LinkWrapper>
        </SlidesContainer>
      </ScrollableSlider>
    </Container>
  );
};

export default CoreProcesses;
