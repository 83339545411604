import React, {
  useRef, createRef, FC, useEffect,
} from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IWidget, IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import { ScrollableSlider } from 'UI';

import SlideItem from './Slide/Slide';

import { Container, SlidesList } from './styled';

const query = graphql`
  query {
    strapiWidgets(title: {eq: "techSlider"}, page: {eq: "main"}) {
      title
      strapiId
      slide {
        header
        id
        title
        media {
          ...MediaImageFragment
        }
        text {
          text
        }
      }
    }
  }
`;

interface ITechSlider {
  slides: IComponentWidgetSlide<IMediaImage>[];
}

const TechSlider: FC<ITechSlider> = ({ slides }) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();

  /**
   * Slides refs
   */
  const slideRefs = useRef(slides.map(() => createRef<HTMLDivElement>()));

  let activeSlide = 0;
  const slidesList = slides.map(({ title }) => title);

  const setActiveClass = (slide: number) => {
    const nodes = slideRefs.current;

    if (!nodes) return;

    nodes.forEach(({ current }, index) => (index === slide ? current?.classList.add('active') : current?.classList.remove('active')));
  };

  const handleChangeSlide = (slide: number = activeSlide) => {
    activeSlide = slide;

    setActiveClass(slide);
  };

  const handleVisibility = (visibility: boolean) => {
    if (visibility) handleChangeSlide(activeSlide);
  };

  useEffect(() => {
    if (containerRef.current) setActiveClass(0);
  }, []);

  setActiveClass(0);

  return (
    <Container ref={containerRef}>
      <ScrollableSlider
        ref={sliderRef}
        items={slidesList}
        onChangeSlide={handleChangeSlide}
        onChangeVisibility={handleVisibility}
        appear="scaleVisible"
      >
        <SlidesList>
          {slides.map(({
            title, header, text, media, id,
          }, index) => (
            <SlideItem
              title={title}
              text={Array.isArray(text) ? text : [text]}
              header={header}
              media={media}
              id={id}
              key={`slider-${title}`}
              ref={slideRefs.current[index]}
              index={index}
            />
          ))}
        </SlidesList>
      </ScrollableSlider>
    </Container>
  );
};

interface ISliderWrapper {
  strapiWidgets: IWidget<IMediaImage, IMediaImage>;
}

const SliderWrapper: FC = () => (
  <StaticQuery
    query={query}
    render={(data: ISliderWrapper) => {
      if (!data?.strapiWidgets) return null;
      const { slide = [] } = data.strapiWidgets;

      return (
        <TechSlider
          slides={slide}
        />
      );
    }}
  />
);

export default SliderWrapper;
