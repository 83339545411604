import React, { forwardRef } from 'react';

import { IComponentWidgetSlide } from 'interfaces/widgets';
import { IMediaImage } from 'interfaces/media';

import {
  SlideTitle,
  SlideText,
  Picture,
  Content,
  SliderContent,
  Background,
} from './styled';

interface ISlideItem extends IComponentWidgetSlide<IMediaImage> {
  index: number;
}

const SlideItem = forwardRef<HTMLDivElement, ISlideItem>(
  ({
    title, text, media, index, header,
  }, ref) => (
    <Content ref={ref} data-index={index} className={index === 0 ? 'active' : ''}>
      <SliderContent withPaddings>
        <SlideTitle>{header}</SlideTitle>
        {text.map((textes, key) => (
          <SlideText
            // eslint-disable-next-line react/no-array-index-key
            key={`techSlide-text_${key}`}
            className={`techSlide-${index}`}
          >
            {textes.text}
          </SlideText>
        ))}
      </SliderContent>
      <Background>
        <Picture
          {...media}
          alt={title}
        />
      </Background>
    </Content>
  ),
);

export default SlideItem;
